:root {
    --swiper-navigation-size: 18px
}
:root {
    --swiper-theme-color: #ff4400
}
.swiper-button-next, .swiper-button-prev {
    position: absolute;
    top: 40%;
    width: calc(var(--swiper-navigation-size) * 2.5);
    height: calc(var(--swiper-navigation-size) * 2.5);
    margin-top: calc(0px - (var(--swiper-navigation-size)/ 2));
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--swiper-navigation-color, var(--swiper-theme-color));
    border-radius: 100px;
    background-color: rgb(255 255 255 / 50%);
}
@media screen and (max-width: 799px) {
    .swiper-button-next, .swiper-button-prev {
        display: none;
    }
}
.swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
    opacity: .35;
    cursor: auto;
    pointer-events: none
}
.swiper-button-next:after, .swiper-button-prev:after {
    font-family: swiper-icons;
    font-size: var(--swiper-navigation-size);
    text-transform: none!important;
    font-weight: bolder;
    letter-spacing: 0;
    text-transform: none;
    font-variant: initial;
    line-height: 1
}
.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
    left: 10px;
    right: auto
}
.swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after {
    content: 'prev'
}
.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
    right: 10px;
    left: auto
}
.swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after {
    content: 'next'
}
.swiper-button-next.swiper-button-white, .swiper-button-prev.swiper-button-white {
    --swiper-navigation-color: #ffffff
}
.swiper-button-next.swiper-button-black, .swiper-button-prev.swiper-button-black {
    --swiper-navigation-color: #000000
}
.swiper-button-lock {
    display: none
}